.ezy__signin6 {
	/* Bootstrap variables */
	--bs-body-color: #333b7b;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-form-card-bg: #ffffff;
	--ezy-form-card-shadow: none;

	background-color: var(--bs-body-bg);
	padding: 40px 0;
	overflow: hidden;
}

/* Gray Block Style */
.gray .ezy__signin6,
.ezy__signin6.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-form-card-bg: #f6f6f6;
	--ezy-form-card-shadow: none;
}

/* Dark Gray Block Style */
.dark-gray .ezy__signin6,
.ezy__signin6.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
	--bs-dark-rgb: 255, 255, 255;

	/* Easy Frontend variables */
	--ezy-form-card-bg: #253140;
	--ezy-form-card-shadow: none;
}

/* Dark Block Style */
.dark .ezy__signin6,
.ezy__signin6.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);
	--bs-dark-rgb: 255, 255, 255;

	/* Easy Frontend variables */
	--ezy-form-card-bg: #162231;
	--ezy-form-card-shadow: none;
}

.ezy__signin6 .container {
	min-height: 100vh;
}

.ezy__signin6-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

.ezy__signin6-bg-holder {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 1450px;
	width: 100%;
	/* border-radius: 25px; */
}

.ezy__signin6-form-card {
	background-color: transparent;
	border: none;
	box-shadow: var(--ezy-form-card-shadow);
	/*border-radius: 15px;*/
}

.ezy__signin6-form-card * {
	color: var(--bs-body-color);
}

.ezy__signin6 .form-control {
	min-height: 48px;
	line-height: 40px;
	border-color: transparent;
	background: rgba(163, 190, 241, 0.14);
	border-radius: 10px;
	color: var(--bs-body-color);
}

.ezy__signin6 .form-control:focus {
	border-color: var(--ezy-theme-color);
	box-shadow: none;
}

.ezy__signin6-btn-submit {
	padding: 12px 30px;
	background-color: #ffc107 !important ;
	color: #ffffff;
}

/* .ezy__signin6-btn-submit:hover {
	color: #ffffff;
} */

.ezy__signin6-btn {
	padding: 12px 30px;
}

.ezy__signin6-btn,
.ezy__signin6-btn * {
	color: #ffffff;
}

.ezy__signin6-btn:hover {
	color: #ffffff;
}

.ezy__signin6-or-separator {
	position: relative;
}

.ezy__signin6-or-separator hr {
	border-color: var(--bs-body-color);
	opacity: 0.15;
}

.ezy__signin6-or-separator span {
	background-color: var(--ezy-form-card-bg);
	color: var(--bs-body-color);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	opacity: 0.8;
}
.image-logo{
	width:190px;
}
.logo-kapna{
	text-align: center;
}
.login-here{
	color:#ffc107 !important;
}
.registration-button{
	background-color: #ffc107 !important;
	color:#f6f6f6;
	border: none !important;
}
.log-button{
	color:#ffc107 !important
}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}
