.logo {
  /* width: 250px; */
  height: 80px;
}
/* .logo-container{
  width: 350px;
  height: 80px;
} */
.navbar-icons:hover {
  color: #ffc107 !important;
}
.header-button:hover {
  color: #ffc107 !important;
}
.offcanvas-icons:hover {
  color: #ffc107 !important;
}
.main-icons {
  color: black !important;
  text-decoration: none;
  text-align: center;
  justify-content: center;
  /* padding: 20px; */
  margin-left: 10px;
}
.store-headlinediv{
  height: 50px !important;
  overflow: hidden; /* Hide overflow to keep text within bounds */
  white-space: nowrap; /* Prevent text from wrapping */
  position: relative; /* Position relative to contain absolute positioning */
  width: 100%; /* Full width of the container */
  background-color: Black;
  color: white;
}
.store-headline {
  font-size: 1.2rem; /* Adjust the font size as needed */
  position: absolute; /* Use absolute positioning for moving effect */
  animation: moveText 12s linear infinite; /* Animation properties */
  margin-top: 10px !important;
}
.login-button {

  margin-left: 20px !important;
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  color: black !important;
  background-color: #ffc107 !important;
  border: none !important;
  border-radius: 10px !important;
}

.header-logo {
  flex-wrap: nowrap;
}

.button-container {
  display: flex;
}




.search-button:hover {
  background-color: #ffc107 !important;
  color: black !important;
}

.search-button {
  height: 38px !important;
  border-radius: 5px !important;
  background-color: transparent !important;
  border-color: #ffc107 !important;
}

.navbar-icons {
  text-decoration: none;
  text-align: center;
  justify-content: center;
  padding: 20px;
  color: white;
  margin-left: 10px !important;
}
.toggle-button {
  background-color: grey !important;
  color: white !important;
  border: none !important;
}
.nav-bar {
  background-color: black !important;
  opacity: 0.8;
}
.offcanvas-icons {
  color: white !important;
  margin-top: 15px;
}
.flex-column {
  margin-top: 70px;
}
.offcanvas-profile-icon {
  position: absolute;
  top: 23px;
  right: 15px;
  font-size: 18px;
  cursor: pointer;
  color: white !important;
}
a {
  color: white !important;
}
.navbar-settings {
  justify-content: center;
  align-items: center;
  display: flex;
}
.closing-menu-button {
  background-color: grey !important;
  border: none !important;
  box-shadow: 0 8px 16px rgba(252, 248, 248, 0.3);
  border-radius: 60px !important;
}

/* .nav-link {
  color: #333;
  padding: 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #ffc107;
} */
.search-icon {
  background-color: white !important;
  /* width:50%!important; */
  height: 50px;
  text-align: center !important;
  justify-content: center !important;
  display: flex !important;
  border-radius: 10px;
  /* margin-left: 30px !important; */
}
.delivering-row{
  padding:3px !important;
  background-color:rgb(35, 81, 104);
  color:white;
}
.top-profile-icon{
  color:white !important;
}

.header-sign-in-button{
  background-color: transparent !important;
  color:white !important;
  border:none !important;
  font-size: 12px !important;
}
/* General Mobile View Adjustments */
@media(max-width:492px){
  .main-icons{
    font-size: 15px;
  }.header-button {
    font-size: 8px ;
  }
  .logins-buttons{
    font-size: 10px;
  }
  .main-container-icons{
    margin-right: 10px !important;
  }

  /* }.logo-container{
  width: 350px;
  height: 80px;
} */
  }

@media(max-width:418px){
  .main-icons,
  .logins-buttons,
  .login-button,
  .header-button {
    font-size: 12px;
  }
  .logo{
    width:200px;
    height:80px;
  }
  

}
@media(max-width:393px){
  .main-icons,
  .logins-buttons,
  .login-button,
  .header-button {
    font-size: 10px !important;
  }
  .logo {
    width: 120px;
  }
 
}
@media (max-width: 576px) {
  .main-icons,
  .logins-buttons {
    font-size: 12px !important;
  }
  .login-button {
    font-size: 12px !important;
  }
  .header-button {
    font-size: 12px !important;
  }
  .search-button {
    font-size: 10px !important;
  }
}


@media (max-width: 378px) {
  .search-icon {
    height: 40px; /* Decrease height */
    padding: 2px; /* Reduce padding if needed */
    font-size: 7px; /* Optional: Adjust font size */
  }

  .main-icons,
  .logins-buttons {
    font-size: 10px !important;
  }

  .login-button {
    font-size: 8px;
  }
}

.logins-buttons{
  width:100px !important;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  background-color: #ffc107 !important;
  color:black !important;
  border:none !important
}
/* .search-icon{
  justify-content: flex-end;
  display: flex;
} */
 /* @media(max-width:338px){
  .main-container-icons{
    margin-right: 40px !important;
  }
  .logins-buttons{
    margin-right: 10px !important;
    width: 45px !important;
  }
 } */
 @media(max-width:324px){
  .main-icons{
    margin-left:9px;
    font-size: 5px;
  }
  .logins-buttons{
    font-size: 5px;
  }
 }
 @media (max-width: 768px) {
  .header-logo {
    justify-content: space-between;
  }
  .main-icons {
    color: black !important;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    /* padding: 20px; */
    /* margin-left: 10px !important; */
    font-size: 14px;
    font-weight: 400;
  }

  /* .logo {
    width: 80px;
    height: 50px;
  } */
  .login-button {
    font-size: 14px !important;
  }

  .button-container {
    flex-direction: column;
    align-items: flex-end;
  }

  .header-button {
    margin-top: 5px;
  }
  .search-button {
    font-size: 12px !important;
  }
  .search-icon {
    background-color: white !important;
    /* width:50%!important; */
    height: 40px !important;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    border-radius: 10px;
    /* margin-left: 30px !important; */
  }
/* .logo-container{
  width: 250px;
  height: 80px;
} */
}

@media(max-width:337px){

  .main-icons{
    margin-left: 5px !important;

  }
}