.logo-image{
    display: flex !important;
    text-align: center !important;
    justify-content: center;
    height:100px !important;
    width:130px !important;
   
}
.profile-view{
    display: flex !important;
    text-align: center !important;
    justify-content: center;
}
.profile-id{
    color:#ffc107 !important;
}
.joining-date{
justify-content: flex-end !important;
display: flex;
align-items: flex-end;
}
.sponser-id{
    justify-content: flex-start !important;
    display: flex;
    align-items: flex-start;
}
.profile-ranking{
  margin-top: 35px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 15px;
    background-color:#ffc107;
    margin-bottom: 35px;
    transition: all 0.3s ease;
}
.profile-page {
    margin-top: 35px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 15px;
    background-color:white;
    margin-bottom: 35px;
    transition: all 0.3s ease;
  }
.profile-pages {
    margin-top: -9px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 10px;
    background-color:#ffc107;
    color:white;
    margin-bottom: 15px;
    transition: all 0.3s ease;
  }
 .profile-position{
  color:white;
}
.sponser-details{
  margin-top: -25px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 10px;
  background-color: rgb(255, 106, 0);
  color:white;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}
.activation-status{
  margin-top: -9px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 10px;
    background-color:grey;
    color:white;
    margin-bottom: 15px;
    transition: all 0.3s ease;
}
.profile-referals{
  margin-top: -9px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 10px;
  background-color:rgb(214, 207, 207);
  color:rgb(6, 6, 6);
  margin-bottom: 15px;
  transition: all 0.3s ease;
}
.profile-contents{
  display:flex;
}

