.footer {
    background-color: black;
    color:white;
    padding: 20px 0;
  }
  
  .footer .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .footer .list {
    list-style: none;
    padding: 0;
  }
  
  .footer .list li {
    margin-bottom: 10px;
  }
  
  .footer .list li a {
    text-decoration: none;
    color: #f5f2f2;
  }
  
  .footer .list li a:hover {
    color:  #ffc107;
  }
  
  .footer .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
    font-size: 20px;
  }
  
  .footer .social-icons a {
    color: #fdfafa;
  }
  
  .footer .social-icons a:hover {
    color: #ffc107;
  }
  
  .footer p {
    margin: 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .footer .text-md-left {
      text-align: center !important;
    }
  }
  .the-logo{
    text-align: center;
    justify-content: center;
    padding-top: 15px;
  }
  .footer-logos {
    height: 80px;
    margin-left: -40px;
  }
  .footer-logos{
    margin-left: 4px;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  