.profile-page {
  margin-top: 35px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 15px;
  background-color: #f9f9f9;
  margin-bottom: 35px;
  transition: all 0.3s ease;
}


.profile-page:hover {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
}
/* .profile-user{
  font-weight: bold; */
/* } */
.referral-number{
  align-items: end;
  justify-content: flex-end;
  display: flex !important;
}

.greeting {
  font-size: 18px;
  font-weight: 600;
}

.user {
  color: #ffc107;
  font-weight: 700;
}

.profile-header {
  margin-bottom: 15px;
}
.profile-details{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile-status p,
.referral-id p {
  font-size: 16px;
  color: #555;
}

.profile-bonus p {
  font-size: 16px;
  margin-bottom: 8px;
}

.button-user {
  background-color: #ffc107;
  color: white;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
}

.button-user:hover {
  background-color: #e0a800;
  color: white;
}

.user-buttons {
  background-color: #ffffff;
  color: #100f0f;
  border: 1px solid #e0e0e0;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.user-buttons:hover {
  background-color: #ffc107;
  color: white;
  transform: translateY(-3px);
}

hr {
  border: 0;
  border-top: 1px solid #eeeeee;
}

@media (max-width: 768px) {
  .sidebar {
    display: block !important; /* Always show sidebar on larger screens */
  }
  .user-buttons {
    padding: 10px;
    font-size: 13px;
  }
  h5 {
    font-size: 16px;
  }
  .button-user {
    font-size: 13px;
  }
  .referral-id{
    text-align: left;
  }
  .profile-bonus{
    text-align: left;
  }
}

@media (max-width: 576px) {
  .user-buttons {
    padding: 8px;
    font-size: 12px;
  }
  .button-user {
    font-size: 12px;
  }
  .referral-id{
    text-align: left;
  }
  .profile-bonus{
    text-align: left;
    
  }
}
.profile-sharing {
  background-color: transparent !important;
  border: none;
  color: black;
  font-weight: 200 !important;
}
.profile-menu {
  text-decoration: none;
  color: black !important;
  font-weight: 500;
  display: block;
  margin-bottom: 10px; /* Adds space between the links */
}
.profile-break{
  border-color: black;
  border-width: 2px;
}
.menu-button {
  background-color: grey;
  color: #100f0f;
  border: none;
  font-size: 24px;
}

.sidebar {
  position: absolute;
  left: 0;
  top: 50px; /* Adjust based on your header height */
  width: 200px; /* Width of the sidebar */
  background-color: #f9f9f9; /* Sidebar background color */
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it appears above other elements */
}

.profile-image{
  width:100%;
  height:175px
}
.referral-number{
  text-align: left;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center; 
  background-image: url(https://w0.peakpx.com/wallpaper/235/167/HD-wallpaper-red-grunge-texture-creative-red-background-grunge-art-grunge-texture-red-backgrounds-thumbnail.jpg); 
  background-repeat: no-repeat;
background-size: cover;
  
}

.profile-camera {
  background-color: black; /* Ensures black background color */
  /* position: absolute; */
  /* top: 0px !important; Adjust this value to control the vertical position */
  /* right: 0; */
  bottom:7px;

  transform: translate(0, -50%); /* Aligns it to the top without horizontal adjustment */
  border-radius: 50%;
  background-color: white;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-size: 16px;
  cursor: pointer;
}
.menus-button{
  margin-top: 25px !important;
  width:150px !important;
  background-color: grey !important;
  color: #100f0f !important;
  border:none !important
}
.editing-info{
  text-align:center;
  align-items:center;
  display: flex;
}
.profile-editing{
  color:#100f0f !important
}
.btn-profile-img{
  background-color: #ffc107 !important;
  color:white;
  border: none;
  margin-top: 10px;

}
.profile-name{
  color:white !important;
  margin-top: 10px;
}

.profile-updated{
  background-color: #c7c3c3;
}
.prof-image{
  width:200px !important;
  height:200px !important
}
