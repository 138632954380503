.table-container {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  
  .level-table {
    width: 100%;
    max-width: 600px;
    border-collapse: collapse;
    text-align: left;
  }
  
  .level-table th,
  .level-table td {
    padding: 8px 12px;
    border: 1px solid #ddd;
  }
  
  .level-table th {
    background-color: #f4f4f4;
  }
  
  .level-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  