
.edit-button{
    display: flex !important;
    justify-content: center;
}
.ezy__signin6-btn-submit{
    width:200px;
    color:white !important;
}
.ezy__signin6-btn-submits{
    width:200px;
    background-color: orange !important;
    color:white !important;
}
.cancel-button{
    width:200px;
    background-color: white !important;
    color:orange !important;
}