.about-container {
  padding: 20px;
}

.about-img {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  object-fit: cover;
}

.about-details,
.quality-paragraph {
  text-align: justify;
  margin: 15px 0;
  font-size: 1rem;
  line-height: 1.6;
}
.about-heading{
  margin-top: 15px;
}

/* Ensuring images and text scale properly */
.img-fluid {
  max-width: 100%;
  height: auto;
}

/* Responsive Layout Adjustments */
@media (max-width: 768px) {
  .about-details,
  .quality-paragraph {
    font-size: 1rem;
    padding: 0 10px;
  }

  h5 {
    font-size: 1.25rem;
    margin-top: 20px;
  }

  .col-md-4,
  .col-md-8 {
    text-align: center;
    margin-bottom: 20px;
  }
  .about-heading{
    margin-top: -30px !important;
  }
}

@media (max-width: 576px) {
  .about-details,
  .quality-paragraph {
    font-size: 0.9rem;
    padding: 0 5px;
  }

  h5 {
    font-size: 1.1rem;
    margin-top: 15px;
  }
  .about-heading{
    margin-top: -30px !important;
  }
}
