.insurance-container {
    padding: 20px;
    text-align: center;
  }
  
  .pdf-viewer {
    width: 100%;
    height: 500px; /* Adjust as needed */
    border: 1px solid #ddd;
    overflow: auto;
  }
  .insurance-heading{
    color: #ffc107;
  }
  