.wallet-amount{
    color:  #e0a800;
}
.wallet-earned{
    justify-content: center !important;
    text-align: center !important;
    color:black;
}
.wallet-side{
    margin-top: 20px;
}
.wallet-design{
    border-radius: 10px;
}
.wallet-earning{
   
    color:black;
    text-align: center;
}
.amount-details{
    box-shadow: 0 4px 15px rgba(123, 113, 113, 0.693);

}
.wallet-amounts{
    color:  #e0a800;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    display: flex;
    font-size: 30px;
}
.money-icons{
   justify-content: center;
} 