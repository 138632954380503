.email-for-forget{
    height: 50px;
    border-radius: 10px;
}
.email-name{
    color: rgb(9, 148, 212);
}
/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

/* body {
    background-color: #f4f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
} */

/* Main container for the form */
.forget-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.form-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.form-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
}

/* Form elements */
.forget-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.form-label {
    font-size: 14px;
    color: #555;
}

.form-input {
    padding: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    transition: all 0.3s ease;
}

.form-input:focus {
    border-color: #5e9cfc;
    box-shadow: 0 0 4px rgba(94, 156, 252, 0.6);
}

.otp-container {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.otp-input {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.otp-input:focus {
    border-color: #5e9cfc;
    box-shadow: 0 0 4px rgba(94, 156, 252, 0.6);
}

.submit-btn {
    background-color:#ffc107;
    color: #fff;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: white;
    color:#ffc107
}

.submit-btn:active {
    background-color: #3b6cb8;
}

/* Responsive Design */
@media (max-width: 600px) {
    .form-container {
        padding: 20px;
    }

    .form-title {
        font-size: 20px;
    }

    .form-input {
        font-size: 12px;
        padding: 8px;
    }

    .otp-input {
        font-size: 16px;
    }

    .submit-btn {
        font-size: 14px;
    }
}

.send-otp-button {
    color: #ffc107 !important;
    display: flex !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    border: none !important;
    margin-left: auto; /* This will push the button to the end of the line */
}

.error-message{
    color:red !important
}