.custom-two-image {
  object-fit: contain;
  width: 160px;
  height: 140px;
  padding: 8px;
}
.custom-one-image{
  object-fit: contain;
  width: 180px;
  height: 130px;
  /* padding: 8px; */
}
.custom-three-image{
  object-fit: contain;
  width: 180px;
  height: 130px;
  padding: 8px;
}

.custom-image-contai {
  margin: 15px 0; /* Add spacing between the rows */
}

@media (max-width: 1200px) {
  /* .custom-image {
    width: 100px;
    height: 90px;
  } */
  .custom-image-contai {
    margin: 12px 0; /* Add spacing between the rows */
  }
}

@media (max-width: 992px) {
  /* .custom-image {
    width: 100px;
    height: 80px;
  } */
  .custom-two-image{
    width: 120px;
    height: 110px;
   }
   .custom-one-image{
    width: 120px;
    height: 90px;
   }
   .custom-three-image{
    width: 120px;
    height: 100px;
   }
   
   
  .custom-image-container {
    margin: 10px 0; /* Add spacing between the rows */
  }
  .carousel-text {
    font-size: 30px !important;
  }
  .card-img-top {
    width:200px;
  }
}


@media (max-width: 768px) {
  .custom-image-container {
    margin: 5px !important;
  }
  .custom-one-image{
    width: 130px;
    height: 100px;
   }
   .custom-two-image{
    width: 120px;
    height: 100px;
   }
   .custom-three-image{
    width: 130px;
    height: 100px;
   }

  /* .custom-image {
    width: 60px;
    height: 60px;
  } */

  .carousel-text {
    font-size: 28px !important;
  }
}

@media (max-width: 576px) {
  /* .custom-image {
    width: 50px;
    height: 50px;
  } */
  .custom-one-image{
    width: 120px;
    height: 100px;
   }
   .custom-two-image{
    width: 120px;
    height: 100px;
   }
   .custom-three-image{
    width: 100px;
    height: 100px;
   }
  .carousel-text {
    font-size: 24px !important;
  }
  .card-img-top{
    width:100px
  } 
  .full-screen-carousel {
    height: 50%; /* Further adjust height for very small screens */
  }
}

/* Carousel container */
.full-screen-carousel {
  object-fit: fill !important;
  position: relative;
  width: 100%;
  height: 500px !important;
  overflow: hidden;
}

/* Full-screen responsive carousel images */
.full-screen-carousel img {
  width: 100%; /* Full width of the screen */
  height: 100%; /* Ensure images maintain equal height */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  display: block; /* Ensure no extra space around the images */
}

@media (max-width: 768px) {
  .full-screen-carousel {
    height: 60%; /* Adjust height for smaller screens */
  }
}



.home-image {
  object-fit:fill; /* Maintain equal heights and fit images without distortion */
  width: 100%;
  height: 550px !important;
}

/* .card-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: stretch;
} */

.card-container{
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display:flex
}

.home-card {
  background-color: #090909 !important; /* Light background */
  color: #faf6f6 !important; /* Dark text */
  transition: transform 0.3s ease; /* Smooth hover effect */
  height: 280px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  border: none;
}

.home-card:hover {
  transform: translateY(-10px); /* Lift effect on hover */
}

.card-img-top {
  width:250px;
  height: 250px;
  object-fit: contain;
}

.card-details {
  padding: 10px;
  font-size: 15px;
}
.card-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.full-screen-carousel {
  position: relative;
  height: 100vh;
}

.home-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.carousel-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: "Arial", sans-serif;
  animation: fadeInOut 3s ease-in-out infinite;
  font-size: 40px;
  font-weight: bolder;
}
/* Keyframes for fading in and fading out */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Override the default color of the control buttons */
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Change the control button color to black */
  border-radius: 50%; /* Optional: make the buttons round */
  width: 40px !important; /* Increase the width of the button icon */
  height: 40px !important;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10% !important; /* Adjust the width of the control button area */
}

/* Optional: Add hover effect for a smoother user experience */
.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  background-color: rgba(0, 0, 0, 0.2); /* Darken on hover */
}

.moving-textdiv {
  height: 50px !important;
  overflow: hidden; /* Hide overflow to keep text within bounds */
  white-space: nowrap; /* Prevent text from wrapping */
  position: relative; /* Position relative to contain absolute positioning */
  width: 100%; /* Full width of the container */
  background-color: #b2b38c;
  color: white;
}

.moving-text {
  font-size: 1.2em; /* Adjust the font size as needed */
  position: absolute; /* Use absolute positioning for moving effect */
  animation: moveText 12s linear infinite; /* Animation properties */
  margin-top: 10px !important;
}

@keyframes moveText {
  0% {
    transform: translateX(500%); /* Start outside the right */
  }
  100% {
    transform: translateX(-100%); /* Move to outside the left */
  }
}
.img-fluid {
  width: 650px;
  height: 420px !important;
}
.mission-notes {
  text-align: justify;
}
.three-logos {
  width: 100%;
  height: 100px;
}
.carousel-item {
  display: flex; /* Ensure flex display */
  flex-direction: row; /* Row direction for images */
  justify-content: center; /* Center images */
  align-items: center; /* Center vertically */
}

/* .card-img-top {
  width: 100%; 
  height: auto; 
} */

/* Ensure the images are stacked on smaller screens */
@media (max-width: 767px) {
  .carousel-item {
    flex-direction: column; /* Stack images vertically on small screens */
  }
}
/* ResponsiveCardContainer.css */

.card-container {
  overflow: hidden;
}















.scrollable-row {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}

.scrollable-row .col {
  flex: 0 0 auto;
  width: 80%; /* Adjust as needed to control visible card width */
}

.scrollable-row::-webkit-scrollbar {
  display: none; /* Hide scrollbar for a cleaner look */
}
.fullscreen-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.welcoming{
  font-weight:bolder;
}