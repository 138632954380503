/* ContactUs.css */
.contact-heading {
    margin-bottom: 20px;
  }
  
 
  iframe {
    border-radius: 8px;
  }
  .contact-form{
    margin-top: 78px !important;
    margin-bottom: 78px !important;
  }
  .form-details{
   
        border: 1px solid #ccc; /* Border color */
        border-radius: 8px; /* Rounded corners */
        padding: 20px; /* Inner spacing */
        background-color: #f9f9f9; /* Background color */
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
  }    
  
  